import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faRedo } from '@fortawesome/free-solid-svg-icons'

import "../utils/global.css"
import "../utils/prismjs.css"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { slug, previous, next } = this.props.pageContext
    const featuredImage = post.frontmatter.featuredImage || this.props.data.noFeaturedImage

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={post.frontmatter.featuredImage}
          slug={slug}
        />

        <div
          style={{
            position: `relative`,
          }}
        >
          <Img
            fluid={featuredImage.childImageSharp.fluid}
            style={{
              zIndex: 0,
            }}
          />

          <div
            style={{
              position: `absolute`,
              top: `50%`,
              transform: `translateY(-50%)`,
              zIndex: 10,
              backgroundColor: `rgba(255, 255, 255, 0.75)`,
              width: `100%`,
            }}
          >
            <div
              style={{
                position: `absolute`,
                top: rhythm(-1 / 4),
                left: rhythm(-1 / 4),
                boxShadow: `2px 3px 4px rgba(0, 0, 0, 0.25)`,
                color: `#DBD8D7`,
                letterSpacing: `.1em`,
                zIndex: 30,
                ...scale(-1 / 6),
              }}
            >
              <span
                style={{
                  display: `inline-block`,
                  backgroundColor: `#DA753C`,
                  paddingLeft: rhythm(1 / 4),
                  paddingRight: rhythm(1 / 4),
                }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{
                    marginRight: rhythm(1 / 6),
                  }}
                />
                {post.frontmatter.date}
              </span>
              { post.frontmatter.lastUpdate != null &&
                <span
                  style={{
                    display: `inline-block`,
                    backgroundColor: `#DA3C3C`,
                    paddingLeft: rhythm(1 / 4),
                    paddingRight: rhythm(1 / 4),
                  }}
                >
                  <FontAwesomeIcon
                    icon={faRedo}
                    style={{
                      marginRight: rhythm(1 / 6),
                    }}
                  />
                  {post.frontmatter.lastUpdate}
                </span>
              }
            </div>

            <h1
              style={{
                ...scale(1 / 2),
                marginTop: rhythm(3 / 2),
                marginBottom: rhythm(1),
                marginLeft: rhythm(1 / 2),
                zIndex: 20,
              }}
            >
              {post.frontmatter.title}
            </h1>

            <div
              style={{
                textAlign: `right`,
                position: `absolute`,
                bottom: `-10px`,
                right: `0px`,
                zIndex: 30,
              }}
            >
              <span
                style={{
                  display: `inline-block`,

                  backgroundColor: `rgba(51, 51, 51, 0.8)`,
                  paddingLeft: rhythm(1 / 2),
                  paddingRight: rhythm(1 / 4),
                  color: `#52C5CC`,
                  letterSpacing: `.1em`,
                }}
              >
                記事カテゴリ:
                {' '}
                {post.frontmatter.category || "なし"}
              </span>
            </div>

          </div>

        </div>

        <div
          style={{
            marginBottom: rhythm(1 / 2),
            padding: rhythm(1 / 4),
            borderBottomLeftRadius: `.4em`,
            backgroundColor: `rgba(255, 255, 255, 0.7)`,
          }}
        >

          {/* ※この辺にタグの情報が入る予定（タグがない場合にはこの↓hrごと表示させない）
          <hr
            style={{
              marginBottom: rhythm(1 / 4),
            }}
          /> */}

          <div
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          <hr
            style={{
              marginBottom: rhythm(1 / 2),
            }}
          />
          {/* ※この辺にSNS共有用のボタン置きたい
          <br /> */}
          <div
            style={{
              textAlign: `right`,
            }}
          >
            タイトル: {post.frontmatter.title}<br />
            記事カテゴリ: {post.frontmatter.category}<br />
            投稿日時: {post.frontmatter.date}<br />
            最終更新日時: {post.frontmatter.lastUpdate}<br />
          </div>

        </div>

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            marginLeft: rhythm(1),
            marginRight: rhythm(1),
          }}
        >
          <li style={{ flex: `1 1 auto`,}}>
            {previous && (
              <Link to={previous.fields.slug} rel="prev" style={{ color: `#87CEEB`, }}>
                ← {previous.frontmatter.title} [{previous.frontmatter.date}]
              </Link>
            )}
          </li>
          <li style={{ textAlign: `right`, flex: `1 1 auto`,}}>
            {next && (
              <Link to={next.fields.slug} rel="next" style={{ color: `#87CEEB`, }}>
                {next.frontmatter.title} [{next.frontmatter.date}] →
              </Link>
            )}
          </li>
        </ul>

      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    noFeaturedImage: file(absolutePath: { regex: "/no_featured_image.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY/MM/DD(ddd)")
        lastUpdate(formatString: "YYYY/MM/DD(ddd)")
        category
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
